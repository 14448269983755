<template>
  <b-card>
    <b-card-header>
      <h2>
        <feather-icon icon="LayersIcon" />
        {{ bankData.bancoId == null ? $t('Routes.AddBank') : $t('Routes.EditBank') }}
      </h2>
    </b-card-header>
    <b-card-body>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="required|max:250"
              >
                <b-form-group
                  :label="$t('AppTI.accountBank.bankName')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input v-model="bankData.nombre" />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="required|max:10"
              >
                <b-form-group
                  :label="$t('AppTI.accountBank.shortName')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input v-model="bankData.nombreCorto" />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="float-right mt-2 mb-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="addingBank" small class="mr-1" />
              {{ bankData.bancoId == null ? $t("Lists.Add") : $t("Lists.Edit") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import { required, max } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import generalDataService from '@/services/generalData.service'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    ValidationObserver,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BSpinner,
    ValidationProvider,
  },
  setup() {
    const addingBank = ref(false)
    const bankData = ref({
      bancoId: null,
      nombre: '',
      nombreCorto: '',
    })
    const blankBank = {
      bancoId: null,
      nombre: '',
      nombreCorto: '',
    }

    const resetBankData = () => {
      bankData.value = JSON.parse(JSON.stringify(blankBank))
    }

    const {
      fetchBank,
      createBank,
      updateBank,
    } = generalDataService()

    if (router.currentRoute.params.bancoId) {
      fetchBank(router.currentRoute.params.bancoId, data => {
        bankData.value = data
      })
    } else {
      bankData.value = JSON.parse(JSON.stringify(blankBank))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBankData)

    const onSubmit = () => {
      addingBank.value = true
      if (bankData.value.bancoId === null) {
        createBank(bankData.value, () => {
          addingBank.value = false
          router.push({ name: 'apps-bank-list' })
        })
      } else {
        updateBank(bankData.value.bancoId, bankData.value, () => {
          addingBank.value = false
          router.push({ name: 'apps-bank-list' })
        })
      }
    }

    return {
      bankData,
      addingBank,
      required,
      max,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
    }
  },
}

</script>
